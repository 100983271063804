import React from "react";
import CtaSectionHeader from "./CtaSectionHeader";
import SeoTextHeader from "./SeoTextHeader";

type Props_ListingHeader = {
  lang: string;
  currentCatClass: string;
  category: string | null;
  jsonDataGroups;
  //seoData:Types_SeoData
};

const ListingHeader = (props: Props_ListingHeader) => {
  const selectedCategory = props.category;

  // Ensure selectedCategory is defined
  // If it's defined, remove any extra spaces and convert to lowercase
  // Convert to lowercase because 'group-code' in API was all uppercase, which was causing issues
  const normalizedCategory = selectedCategory
    ? selectedCategory.trim().toLowerCase()
    : "";

  // Debugging logs
  console.log("Selected Category:", normalizedCategory);

  // Search through data to find an object where 'group-code' matches 'normalizedCategory'
  const matchingGroup = props.jsonDataGroups?.find((group) => {
    console.log("Group Code:", group["group-code"].trim().toLowerCase());
    return group["group-code"].trim().toLowerCase() === normalizedCategory;
  });

  console.log("Matching Group:", matchingGroup);

  // If matchingGroup is found, select the 'group-name' (aka the category name) to render out
  const categoryName = matchingGroup
    ? matchingGroup["group-name"]
    : "Categories";

  return (
    <div id="listing-header-title-wrap" className="container-fluid">
      <div className="container">
        <div id="listing-header" className="row nopad">
          <div className="col-lg-6">
            <h1>{categoryName}</h1>
          </div>
          <div id="col-cta" className="col-lg-6">
            <CtaSectionHeader
              lang={props.lang}
              currentCatClass={props.currentCatClass}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListingHeader;
