import React from "react";
import { outputEnFr } from "../../common/HelperFunctions";
import * as Constants from "../../common/Constants";

import imageOne from "../../../images/sup.jpg";
import imageTwo from "../../../images/tons.jpg";
import imageThree from "../../../images/huge.jpg";

const SeoTextSectionBattlefield = (props: { lang: string }) => {
  return (
    <section className="unbeatable">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 text-center">
            <span
              dangerouslySetInnerHTML={{
                __html: outputEnFr(
                  "<h2><span>Unbeatable</span> Value</h2>",
                  "<h2>Une Valeur <span>Imbattable</span></h2>",
                  props.lang
                ),
              }}
            />
          </div>
        </div>
        <div className="row text-center">
          <div className="col-lg-4">
            <img
              className="img-fluid"
              src={imageOne}
              alt="superior maintenance"
            />
            <h3>
              {outputEnFr(
                "Superior Maintenance",
                "Entretien supérieur",
                props.lang
              )}
            </h3>
            <p>
              {outputEnFr(
                "Our equipment is fully serviced by factory trained technicians",
                "Nos équipements sont entièrement entretenus par des techniciens formés en usine",
                props.lang
              )}
            </p>
          </div>
          <div className="col-lg-4">
            <img className="img-fluid" src={imageTwo} alt="tons of parts" />
            <h3>
              {outputEnFr("Tons of Parts", "Des tonnes de pièces", props.lang)}
            </h3>
            <p>
              {outputEnFr(
                "We’re an Authorized Parts Dealer for hundreds of manufacturers",
                "Nous sommes un revendeur de pièces détachées agréé pour des centaines de fabricants",
                props.lang
              )}
            </p>
          </div>
          <div className="col-lg-4">
            <img className="img-fluid" src={imageThree} alt="huge inventory" />
            <h3>
              {outputEnFr("Huge Inventory", "Stock important", props.lang)}
            </h3>
            <p>
              {outputEnFr(
                "New equipment is always being added – check back often",
                "De nouveaux équipements sont constamment ajoutés - revenez souvent",
                props.lang
              )}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

SeoTextSectionBattlefield.whyDidYouRender = true;
export default SeoTextSectionBattlefield;
