import React from "react";
import { types_seoData } from "../Types";

const SEO_BFE: types_seoData[] = [
  {
    CatCode: "Default",
    CatCodeSlug: "products",

    EN: {
      Name: "Jobsite Industrial Rental Services",
      Meta: "At Jobsite Industrial Rental Services, you will find the largest inventory of used Cat compact equipment in Eastern Canada. From landscaping to construction and excavating to road building, our powerhouse selection offers increased value and top-notch performance in all types of environments. Jobsite Industrial Rental Services is your authorized Caterpillar Dealer in Manitoba, Ontario, Quebec, New Brunswick, Nova Scotia, P.E.I., Newfoundland and Labrador.",
      Desc: "At Jobsite Industrial Rental Services, you will find the largest inventory of used Cat compact equipment in Eastern Canada. From landscaping to construction and excavating to road building, our powerhouse selection offers increased value and top-notch performance in all types of environments. Jobsite Industrial Rental Services is your authorized Caterpillar Dealer in Manitoba, Ontario, Quebec, New Brunswick, Nova Scotia, P.E.I., Newfoundland and Labrador.",
      ExtraText: null,
    },
    FR: {
      Name: "Location d'outils industriels Jobsite",
      Meta: "Chez Location d'outils industriels Jobsite, vous trouverez le plus grand inventaire d’équipement usagé Cat dans l’est du Canada. Que ce soit pour des applications de construction, de pavage, de concassage ou forestières, notre sélection d’équipement offre une valeur accrue et une performance inégalée dans tous les types d’environnements. Location d'outils industriels Jobsite est votre concessionnaire Caterpillar autorisé au Manitoba, en Ontario, au Québec, au Nouveau-Brunswick, en Nouvelle-Écosse, à l’Île-du-Prince-Édouard, à Terre-Neuve et Labrador et au Nunavut.",
      Desc: "Chez Location d'outils industriels Jobsite, vous trouverez le plus grand inventaire d’équipement usagé Cat dans l’est du Canada. Que ce soit pour des applications de construction, de pavage, de concassage ou forestières, notre sélection d’équipement offre une valeur accrue et une performance inégalée dans tous les types d’environnements. Location d'outils industriels Jobsite est votre concessionnaire Caterpillar autorisé au Manitoba, en Ontario, au Québec, au Nouveau-Brunswick, en Nouvelle-Écosse, à l’Île-du-Prince-Édouard, à Terre-Neuve et Labrador et au Nunavut.",
      ExtraText: null,
    },
  },
  {
    CatCode: "Articulated Trucks - Tombereaux Articulés",
    CatCodeSlug: "articulated-trucks-tombereaux-articules",
    EN: {
      Name: "Articulated Trucks",
      Meta: "Operational efficiency is maximized thanks to our rock-solid selection of used articulated trucks. Our dump trucks are ultra-tough, offering unmatched performance and traction control on rough or uneven terrain.",
      Desc: (
        <>
          <h2>Used Articulated Trucks – Built to Last!</h2>
          <p>
            Does your construction site require an ultra-tough, reliable and
            cost-effective dump truck? Look no further than Jobsite Industrial
            Rental Services's extensive selection of used articulated trucks!
            Regardless of the heavy materials you need to haul or your project's
            budget, Jobsite Industrial Rental Services has the used dump truck
            to meet your specific needs and get the job done right!
          </p>
          <h3>Used Articulated / Dump Trucks for Sale:</h3>
        </>
      ),
      ExtraText: (
        <>
          <h4>Need the Best Used Articulated Truck to start hauling ASAP?</h4>
          <p>
            From Nova Scotia to Nunavut, Jobsite Industrial Rental Services has
            a wide selection of used articulated trucks that are ready to get to
            work! Our used dump trucks are durable and ready to handle payloads
            ranging in weight from 26.5 ton to 45.2 ton. Whatever you need to
            haul away – from rocks to cement to soil – our used articulated
            trucks can carry it all with ease! Our used dump trucks always
            handle rough and uneven terrain like a pro, because of a superior
            traction control system. Jobsite Industrial Rental Services used
            articulated trucks also contain advanced technologies and features
            like tipping and load dumping control, which help to reduce cycle
            times and fuel consumption. Our used dump trucks can come
            Caterpillar Certified and range in price from $25,000 CAD to
            $700,000 CAD.
          </p>
          <br />
          <h5>
            Get Your Used Articulated Truck from Jobsite Industrial Rental
            Services Today!
          </h5>
          <p>
            Experience how much faster and cheaper you can get your hauling done
            with a used articulated truck from Jobsite Industrial Rental
            Services! Caterpillar's used dump trucks are built to last and
            always get the job done right! Discover the difference a Caterpillar
            dump truck can make on your site! Contact us today for more
            information about our used articulated trucks or to get a quote for
            your next project!
          </p>
          <p>
            You can also find more information about our articulated trucks on{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.cat.com/en_US/products/new/equipment/articulated-trucks.html"
            >
              Cat's official site
            </a>
            .
          </p>
        </>
      ),
    },
    FR: {
      Name: "Tombereaux Articulés",
      Meta: "L’efficacité opérationnelle est maximisée grâce à notre sélection solide de tombereaux articulés usagés. Nos tombereaux sont ultra robustes, fournissant une performance accrue et un superbe contrôle de traction sur terrain rugueux ou accidenté.",
      Desc: (
        <>
          <h2>TOMBEREAUX ARTICULÉS USAGÉS</h2>
          <p>
            Est-ce que vous avez besoin d’un tombereau résistant, fiable et
            rentable? Nous avons chez Location d'outils industriels Jobsite un
            choix étendu de tombereaux articulés usagés! Peu importe les
            matériaux lourds que vous devez transporter ou le budget de votre
            projet, Location d'outils industriels Jobsite a le camion articulé
            usagé qui répondra à vos besoins spécifiques et fera le travail
            correctement!
          </p>
        </>
      ),
      ExtraText: (
        <>
          <h4>
            Avez-vous besoin du meilleur tombereau articulé usagé maintenant?
          </h4>
          <p>
            De la Nouvelle-Écosse au Nunavut, Location d'outils industriels
            Jobsite a une vaste sélection de tombereaux articulés usagés prêts à
            se mettre au travail! Nos tombereaux articulés usagés sont durables
            et capables de manipuler des charges utiles allant de 26,5 tonnes à
            45,2 tonnes. Tout ce que vous avez besoin de transporter - des
            roches au ciment en passant par la terre - nos tombereaux articulés
            usagés peuvent tout déplacer avec facilité! Nos tombereaux articulés
            usagés se comportent toujours comme un pro sur les terrains
            accidentés et inégaux, grâce à un système de contrôle de la traction
            supérieur. Les tombereaux articulés usagés Location d'outils
            industriels Jobsite contiennent également des technologies et des
            caractéristiques avancées comme le contrôle de basculement et de
            déversement de la charge, qui aident à réduire les temps de cycle et
            la consommation de carburant. Nos tombereaux articulés usagés
            peuvent être certifiés Caterpillar et leur prix varie de 25 000$ CAN
            à 700 000$ CAN.
          </p>
          <br />
          <h5>
            Achetez votre tombereau articulé usagé chez Location d'outils
            industriels Jobsite dès aujourd'hui!
          </h5>
          <p>
            Découvrez comment vous pouvez effectuer votre transport plus
            rapidement et à moindre coût avec un tombereau articulé usagé
            Location d'outils industriels Jobsite! Les tombereaux articulés
            usagés de Caterpillar sont construits pour durer et toujours bien
            faire le travail! Faites la différence sur votre chantier avec un
            tombereau articulé Caterpillar! Contactez-nous dès aujourd'hui pour
            plus d'information sur nos tombereaux articulés usagés ou pour
            obtenir un devis pour votre prochain projet!
          </p>
          <p>
            Trouvez plus d'information sur nos tombereaux articulés usagés sur
            le site web officiel de Caterpillar.
          </p>
        </>
      ),
    },
  },
  {
    CatCode: "Backhoe Loaders - Chargeuses-pelleteuses",
    CatCodeSlug: "backhoe-loaders-chargeuses-pelleteuses",
    EN: {
      Name: "Backhoe Loaders",
      Meta: "Our used Backhoe Loaders are a must-have for a variety of tasks, such as general construction, demolitions and excavations, landscaping, asphalt breaking and road paving.",
      Desc: (
        <>
          <h2>Used Backhoe Loaders for a Wide Variety of Tasks</h2>
          <p>
            Backhoe loaders are necessary for a variety of tasks, such as
            general construction, demolition and excavating, landscaping,
            asphalt breaking and road paving. Are you looking for a used backhoe
            loader with a powerful engine, superior digging capability and the
            durability to handle any terrain? Jobsite Industrial Rental Services
            carries a large selection of used backhoe loaders to help you
            complete your next project on time and under budget!
          </p>
          <h3>Used Backhoe Loaders for Sale:</h3>
        </>
      ),
      ExtraText: (
        <>
          <h4>Need the Best Used Backhoe Loader for Your Next Project?</h4>
          <p>
            A backhoe loader is an essential piece of equipment on your site, so
            invest in the used backhoe loader that is reliable, versatile and
            can help you get the job done faster! Our used backhoe loaders
            continue to be a popular choice in urban engineering and
            construction projects because of their small, compact design, which
            provides machine versatility and allows them to go virtually
            anywhere. Despite their small size, our used backhoe loaders are
            quite powerful, ranging in net power from 68HP to 131HP. Equipping
            yourself with one of our used backhoe loaders means precise control
            and top-of-the-line performance that will improve construction
            productivity and lower machine operating costs!
          </p>
          <br />
          <h5>
            Get Your Used Backhoe Loader from Jobsite Industrial Rental Services
            Today!
          </h5>
          <p>
            If you need a backhoe loader that can handle challenging terrains,
            carry materials and dig through almost anything, choose a
            Caterpillar! Jobsite Industrial Rental Services has a wide selection
            of used Caterpillar backhoe loaders that come Caterpillar Certified,
            starting at $20,000 CAD. Contact us today for more information about
            our available used backhoe loaders or to get a quote for your next
            project!
          </p>
        </>
      ),
    },
    FR: {
      Name: "Rétrocaveuses",
      Meta: "Nos rétrocaveuses usagées à vendre sont indispensables pour une variété de travaux. Grâce à leur châssis compact, nos pépines d’occasion peuvent aller n’importe où, ce qui en fait un choix populaire pour les projets d’ingénierie ou de construction qui nécessitent une performance supérieure.",
      Desc: (
        <>
          <h2>Rétrocaveuses usagées pour une vaste gamme d’applications</h2>
          <p>
            Les rétrocaveuses sont nécessaires pour une variété de travaux,
            comme la construction, l’excavation, la démolition, l’aménagement
            paysager, le travail d’asphalte et le pavage routier. Cherchez-vous
            une rétrocaveuse usagée avec un moteur puissant, une performance
            supérieure en matière de creusement et la durabilité pour affronter
            n’importe quel terrain? Location d'outils industriels Jobsite offre
            une vaste sélection de pépines usagées pour vous aider à compléter
            votre projet à temps et en deçà du budget!
          </p>
        </>
      ),
      ExtraText: (
        <>
          <h3>
            Besoin de la meilleure rétrocaveuse usagée pour votre prochain
            projet?
          </h3>
          <p>
            Sur votre chantier, une rétrocaveuse est une pièce d’équipement
            essentielle. Il est donc important de choisir une pépine usagée qui
            est fiable, polyvalente et qui peut vous aider à en accomplir
            beaucoup plus! Nos rétrocaveuses usagées sont un choix populaire
            pour les projets d’ingénierie et de construction grâce à leur
            châssis compact – qui leur permet d’aller n’importe où! En dépit de
            leurs dimensions, nos rétrocaveuses usagées sont performantes et
            livrent une puissance de 68 HP à 131 HP. En vous équipant d’une de
            nos rétrocaveuses usagées, vous obtiendrez un contrôle précis et une
            performance inégalable qui amélioreront votre productivité et
            réduiront vos coûts d’entretien!
          </p>
          <br />
          <h4>
            Achetez votre pépine usagée chez Location d'outils industriels
            Jobsite dès aujourd’hui!
          </h4>
          <p>
            Si vous avez besoin d’une rétrocaveuse qui fournit une maniabilité
            précise sur les terrains difficiles, qui porte les matériaux lourds
            et qui creuse partout, choisissez un Cat®! Location d'outils
            industriels Jobsite a une vaste sélection de pépines usagées qui
            peuvent être certifiées Caterpillar, et dont les prix commencent à
            20 000 $ CAD. Contactez-nous aujourd’hui pour obtenir plus
            d’informations sur nos rétrocaveuses usagées ou demander un devis
            pour votre prochain projet!
          </p>
        </>
      ),
    },
  },
  {
    CatCode: "Compactors - Compacteurs",
    CatCodeSlug: "compactors-compacteurs",
    EN: {
      Name: "Compactors",
      Meta: "Jobsite Industrial Rental Services’ Industrial Compactors feature advanced technologies that boost durability, operational performance and efficacy.",
      ExtraText: (
        <>
          <h4>Looking to Purchase a Used Compactor for Your Next Project?</h4>
          <p>
            Compacting to specifications is crucial for a variety of
            applications, so choose the Caterpillar compactor that is specially
            designed to handle your specific compaction tasks. Our used
            compactors feature advanced technologies that boost durability,
            operational performance and efficacy. Whether flattening soil,
            compacting landfill material or smoothing the surface of a newly
            paved road, our selection of combination rollers will offer value
            where it is needed most. If you need a soil compactor in Winnipeg, a
            pneumatic roller in Toronto or a landfill compactor in
            Pointe-Claire, Jobsite Industrial Rental Services has the used
            compactor you need! Our used compactors are affordable (starting at
            $13,500 CAD) and many come Caterpillar Certified.{" "}
          </p>
          <br />
          <h5>
            Get Your Used Compactor from Jobsite Industrial Rental Services
            Today!
          </h5>
          <p>
            If your landfill, agricultural or construction site needs a
            compactor, choose a used Caterpillar compactor from Jobsite
            Industrial Rental Services! We carry a wide selection of compactors
            (soil, vibratory soil and landfill) and rollers (pneumatic and
            tandem vibratory) that are affordable, cost-effective and backed by
            Caterpillar. Contact us today for more information about our range
            of used compactors!{" "}
          </p>
        </>
      ),
      Desc: (
        <>
          <h2>Wide Selection of Used Compactors Available</h2>
          <p>
            Wide Selection of Used Compactors Available Compactors are an
            important tool for many road building, landfill and construction
            sites, so it is essential to choose the used compactor specific to
            your needs. Are you looking for a dependable used compactor for your
            next project? If you require a soil compactor, vibratory soil
            compactor, landfill compactor, pneumatic rollers or tandem vibratory
            rollers, Jobsite Industrial Rental Services has the used Caterpillar
            compactor to match your project needs and get the job done!
          </p>
          <h3>Used Compactors for Sale:</h3>
        </>
      ),
    },
    FR: {
      Name: "Compacteurs",
      Meta: "Les compacteurs d’occasion de Location d'outils industriels Jobsite sont dotés de technologies avancées qui augmentent la durabilité, la performance et l’efficacité pour optimiser votre productivité sur le chantier. Nos rouleaux compacteurs usagés offrent un maximum de valeur pour tous vos besoins.",
      Desc: (
        <>
          <h2>Une vaste sélection de compacteurs usagés disponibles</h2>
          <p>
            Les compacteurs sont un outil important pour de nombreux projets
            d’agriculture, de construction et de compactage. Il est donc
            essentiel de choisir le compacteur le mieux adapté à vos besoins.
            Cherchez-vous un compacteur usagé sûr pour votre prochain projet? Si
            vous avez besoin d’un compacteur du sol, d’un compacteur du sol
            vibrant, d’un compacteur de décharge, d’un rouleau pneumatique ou
            d’un rouleau vibrant, Location d'outils industriels Jobsite a le
            compacteur Cat® usagé qui répondra à vos besoins et fera le travail!
          </p>
        </>
      ),
      ExtraText: (
        <>
          <h4>
            Voulez-vous acheter un compacteur usagé pour votre prochain projet?{" "}
          </h4>
          <p>
            Un compactage conforme aux spécifications est important pour une
            variété d’applications. Mieux vaut donc choisir le compacteur Cat®
            qui a été fabriqué pour vos tâches précises. Nos compacteurs usagés
            sont dotés de technologies avancées qui en augmentent la durabilité,
            la performance et l’efficacité. Notre sélection de rouleaux mixtes,
            utilisés pour une variété de travaux – tels que le compactage de
            décharges et le lissage du sol et de la chaussée –, offre un maximum
            de valeur pour tous vos besoins. Si vous avez besoin d’un compacteur
            de sol à Winnipeg, d’un rouleau pneumatique à Toronto ou d’un
            compacteur de décharge à Pointe-Claire, Location d'outils
            industriels Jobsite a le compacteur usagé fait pour vous! Nos
            compacteurs usagés sont abordables (ils sont offerts à partir de 13
            000 $ CAD) et peuvent être certifiés Caterpillar.
          </p>
          <br />
          <h5>
            Obtenez votre compacteur usagé chez Location d'outils industriels
            Jobsite dès aujourd’hui!{" "}
          </h5>
          <p>
            Si votre projet d’agriculture, de construction ou de compactage
            nécessite un compacteur, choisissez un compacteur Caterpillar de
            Location d'outils industriels Jobsite! Nous offrons une vaste
            sélection de compacteurs et de rouleaux mixtes abordables, rentables
            et garantis par Caterpillar. Contactez-nous aujourd’hui pour obtenir
            plus d’informations à propos de notre gamme de compacteurs usagés!
          </p>
        </>
      ),
    },
  },
  {
    CatCode: "Aggregate - Granulats",
    CatCodeSlug: "aggregate-granulats",
    EN: {
      Name: "Crushers and Screens",
      Desc: (
        <>
          <h2>Improve Your Bottom Line with Our Used Crushers and Screens</h2>
          <p>
            Whether processing raw materials, manufacturing sand or handling
            glass in recycling applications, our used industrial crushing
            equipment will boost productivity and improve your bottom line.
          </p>
          <h3>Used Crushers and Screens Currently for Sale:</h3>
        </>
      ),
      Meta: "Whether processing raw materials, mining sand or handling glass in recycling applications, our used industrial crushing equipment will boost productivity and improve your bottom line.",
      ExtraText: (
        <>
          <h4>Wide Range of Used Crushers and Screens </h4>
          <p>
            Jobsite Industrial Rental Services carries a wide range of used
            crushers and screens for applications in the mining, quarry and
            recycling industries. Whether your job site requires fine grinding
            of stone, glass or other materials, Jobsite Industrial Rental
            Services will have the used crusher to meet your needs. The used
            screenrs we carry are able to process and effectively screen
            construction, demolition, soil and recycling debris. Jobsite
            Industrial Rental Services’s lineup of used crushers and screens
            includes well-known, trusted and reliable brands such as Lokotrack,
            Masaba, Metso and Nordberg CVB. If you need a used crusher and
            screen for your job site, contact Jobsite Industrial Rental Services
            today for more information or a quote!{" "}
          </p>
        </>
      ),
    },
    FR: {
      Name: "Concasseurs et cribles",
      Meta: "Que vous traitiez des matières premières, du sable, ou du verre dans des applications de recyclage, nos concasseurs et nos cribles usagés à vendre augmenteront votre productivité et amélioreront votre rentabilité.",
      Desc: (
        <>
          <h2>
            Améliorer votre rentabilité avec nos concasseurs et nos cribles
            usagés{" "}
          </h2>
          <p>
            Que vous traitiez des matières premières, du sable, ou du verre dans
            des applications de recyclage, nos concasseurs et nos cribles usagés
            à vendre augmenteront votre productivité et amélioreront votre
            rentabilité. Nos broyeurs d’occasion sont plus souvent utilisés dans
            les carrières, les mines et les industries des agrégats, et sont
            conçus pour résister aux environnements les plus rigoureux, tout en
            offrant une performance de haute qualité. Pour tous vos besoins
            industriels, faites confiance aux concasseurs de pierres de Toromont
            Cat.{" "}
          </p>
        </>
      ),
      ExtraText: (
        <>
          <h4>Grande sélection de concasseurs et de cribles usagés</h4>
          <p>
            Location d'outils industriels Jobsite offre une vaste gamme de
            cribles et de concasseurs usagés pour des applications dans les
            industries minières, de carrières et de recyclage. Que votre
            chantier nécessite de l’équipement pour broyer des pierres, du verre
            ou d’autres matériaux, vous pouvez compter sur Location d'outils
            industriels Jobsite pour répondre à vos besoins. Les cribles
            d’occasion que nous offrons sont capables de traiter et de filtrer
            efficacement les débris de construction, de démolition, de sol et de
            recyclage. La sélection de cribles et de concasseurs usagés de
            Location d'outils industriels Jobsite est composée de marques
            fiables et connues telles que Lokotrack, Masaba, Metso et Nordberg
            CVB. Si vous avez besoin d’une crible ou d’un concasseur usagé,
            contactez Location d'outils industriels Jobsite aujourd’hui pour
            obtenir un devis ou plus d’information.
          </p>
        </>
      ),
    },
  },
  {
    CatCode: "Excavators - Pelles Hydrauliques",
    CatCodeSlug: "excavators-pelles-hydrauliques",
    EN: {
      Name: "Excavators",
      Meta: "Excavators are an indispensable asset to any contractor’s fleet. Whatever the application, our selection of used excavators can get the job done safely and efficiently. A digger’s purpose is almost limitless thanks its exceptional speed, energy efficiency and lifting capacity.",
      Desc: (
        <>
          <h2>Used Cat excavators to help you dig up the profits! </h2>
          <p>
            Whatever the application, Jobsite Industrial Rental Services’
            selection of used excavators can get the job done safely and
            efficiently. When you choose to buy used excavators, you are
            obtaining the absolute best machines for the job. Our used
            excavators offer limitless capabilities including exceptional speed,
            fuel efficiency and digging performance. Thanks to their rock-solid
            frames and robust components, our used mini hydraulic excavators
            provide unrivaled strength, durability and reliability on the most
            challenging terrain. From digging trenches and foundations, to post
            holes and breaking concrete, you can count on Jobsite Industrial
            Rental Services’ used mini hydraulic excavators to perform with
            unsurpassed power and agility.
          </p>
          <h3>Our used excavators currently for sale:</h3>
        </>
      ),
      ExtraText: (
        <>
          <h4>
            Jobsite Industrial Rental Services’ wide selection of used
            excavators{" "}
          </h4>
          <p>
            Jobsite Industrial Rental Services used excavators are equipped with
            a long, heavy-duty undercarriage that offers greater stability when
            lifting heavy objects, thus greatly increasing daily productivity.
            Optimal performance and durability are achieved with a variety of
            standard and optional technologies such as{" "}
            <a href="https://www.catconnectconstruction.com/en/">
              Cat Connect solutions
            </a>
            , which optimize production and minimize operating costs, and the
            abrasion-resistant Cat XT™-6 hose, which can withstand high
            pressures over long periods of time. With features such as a quiet,
            ergonomic cab that offers automatic climate control for the
            maintenance of consistent temperature, an LCD monitor that is easy
            to program and use, and large windows that provide a panoramic view,
            Jobsite Industrial Rental Services’ used excavators for sale enable
            you dominate the job site with ease, comfort and security.
          </p>
        </>
      ),
    },
    FR: {
      Name: "Excavatrices",
      Meta: "Les excavatrices usagées à vendre de Location d'outils industriels Jobsite sont un atout indispensable pour la flotte d’un entrepreneur. Quelle que soit l’application, notre sélection de pelles hydrauliques d’occasion fera en sorte que le travail sera accompli avec efficacité et en toute sécurité.",
      Desc: "Les excavatrices usagées à vendre de Location d'outils industriels Jobsite sont un atout indispensable pour la flotte d’un entrepreneur. Quelle que soit l’application, notre sélection de pelles hydrauliques d’occasion fera en sorte que le travail sera accompli avec efficacité et en toute sécurité. Grâce à leur vitesse, leur rendement énergétique et à leur puissance de levage exceptionnelle, Il n’y a pas de limites aux travaux que nos pelles mécaniques peuvent accomplir. Le châssis et les composants robustes de nos pelles sur chaines et nos pelles sur pneus offrent une puissance et une fiabilité supérieures sur les terrains difficiles et dans les environnements rigoureux. Que vous creusiez des tranchées ou des fondations, ou que vous faisiez du paillage forestier ou des travaux miniers, vous pouvez compter sur les excavatrices usagées de Location d'outils industriels Jobsite pour vous fournir une agilité, une productivité et une performance inégalés.",
      ExtraText: (
        <>
          <h4>
            La vaste sélection d’excavatrices usagées de Location d'outils
            industriels Jobsite
          </h4>
          <p>
            Les excavatrices usagées de Location d'outils industriels Jobsite
            sont équipées d’un châssis robuste qui offre une stabilité
            supérieure pendant le transport d’objets lourds, ce qui augmente la
            productivité quotidienne. Une performance et une durabilité
            optimales sont atteintes grâce à une variété de technologies
            intégrées d’usine ou offert en option telles que (insert link), qui
            augmentent la productivité et réduisent les coûts d’exploitation. Le
            tuyau Cat XT™-6 résistant à l’abrasion permet aussi de résister à
            des pressions élevées pendant de longues périodes. Avec des
            fonctionnalités telles que la cabine ergonomique et silencieuse qui
            offre un contrôle de climat automatique pour une température
            constante, un écran LCD facile à programmer et à utiliser, et des
            grandes fenêtres offrant une vue panoramique, les excavatrices
            d’occasion de Location d'outils industriels Jobsite vous permettent
            de maîtrise le chantier avec facilité, confort et sécurité.{" "}
          </p>
        </>
      ),
    },
  },
  {
    CatCode: "Forest Products - Matériel Forestier",
    CatCodeSlug: "forest-products-materiel-forestier",
    EN: {
      Name: "Forest Products",
      Meta: "Jobsite Industrial Rental Services’ used forestry machines will satisfy your toughest application needs. Our commercial logging equipment provide increased stability on steep ground, fuel economy, efficient swing power and maximized visibility.",
      Desc: (
        <>
          <h2>Jobsite Industrial Rental Services’ Used Forestry Machines </h2>
          <p>
            Jobsite Industrial Rental Services’ used forestry machines will
            satisfy your toughest application needs. Our commercial logging
            equipment provide increased stability on steep ground, fuel economy,
            efficient swing power and maximized visibility. Our varied inventory
            of feller bunchers, forwarders and skidders is your top pick for
            harvesting, extracting and loading goods on major projects, such as
            road building, lumber and reforestation.
          </p>
          <h3>Used Forest Machines Currently for Sale: </h3>
        </>
      ),
      ExtraText: (
        <>
          <h4>Used Forest Machines for Any Job </h4>
          <p>
            Jobsite Industrial Rental Services carries a selection of used
            forestry machines than can help complete your commercial logging
            needs. Our used forestry inventory includes feller bunchers,
            forwarders and skidders to meet your extracting, harvesting and
            loading needs. Caterpillar forestry machines are reliable, easy to
            maintain and provide increased stability and visibility on your
            jobsite. Our forestry machines are also powerful, ranging in net
            flywheel power from 149 HP to 291 HP. If your land management,
            logging or mill yard needs require rugged, purpose-built equipment
            to get the job done, contact Jobsite Industrial Rental Services
            today about our used forest machines.
          </p>
        </>
      ),
    },
    FR: {
      Name: "Produits forestiers ",
      Meta: "Les machines forestières d’occasion de Location d'outils industriels Jobsite sauront satisfaire à vos besoins les plus ardus. Notre équipement d’exploitation forestière usagé fournit une superbe stabilité sur le terrain escarpé, une bonne économie de carburant, une visibilité maximisée et une puissance d’oscillation efficace.",
      Desc: "Les machines forestières d’occasion de Location d'outils industriels Jobsite sauront satisfaire à vos besoins les plus ardus. Notre équipement d’exploitation forestière fournit une superbe stabilité sur le terrain escarpé, une bonne économie de carburant, une visibilité maximisée et une puissance d’oscillation efficace. Notre inventaire varié d’abatteuses-groupeuses, de porteurs et de débardeurs usagés à vendre sont votre meilleur choix pour vos travaux d’extraction, de récolte ou de chargement de marchandise sur des projets majeurs de construction de route, d’exploitation forestière ou de reboisement.",
      ExtraText: (
        <>
          <h4>Des machines forestières usagées pour tout type de projet</h4>
          <p>
            Location d'outils industriels Jobsite offre une sélection de
            produits forestiers usagés conçus pour répondre à tous vos besoins
            en matière d’exploitation forestière commerciale. Notre inventaire
            d’équipement forestier usagé, qui comprend des
            abatteuses-groupeuses, des porteurs et des débadeurs, vous permet
            d’accomplir tous vos travaux d'extraction, de récolte et de
            chargement. Les machines forestières Caterpillar sont fiables,
            faciles à entretenir et fournissent une stabilité et une visibilité
            supérieures sur le chantier. Notre équipement forestier est aussi
            très puissant, offrant une puissance d’inertie de 149 HP à 291 HP.
            Si vous nécessitez un équipement robuste et spécialement conçu pour
            vos travaux forestiers, contactez Location d'outils industriels
            Jobsite aujourd’hui pour obtenir plus d’information sur nos machines
            forestières d'occasion.
          </p>
        </>
      ),
    },
  },
  {
    CatCode: "Forklifts - Chariots Elévateurs",
    CatCodeSlug: "forklifts-chariots-elevateurs",
    EN: {
      Name: "Forklifts",
      Meta: "Safety, comfort and lifting capacity are just some of the features that make up a great Forklift. With Toromont Material Handling’s used lift trucks, you can easily cut costs and optimize your logistical operations.",
      Desc: "Safety, comfort and lifting capacity are just some of the features that make up a great forklift. With Toromont Material Handling’s used lift trucks, you can easily cut costs and optimize your logistical operations. Our diesel and electric forklifts are robust, durable, powerful and comfortable to operate, helping to minimize downtime for higher productivity and cost efficiency. Whatever the industry, we have a solution for all of your material handling needs.",
      ExtraText: null,
    },
    FR: {
      Name: "Chariots Elévateurs",
      Meta: "Un bon chariot élévateur doit posséder des caractéristiques supérieures en termes de sécurité, de confort et de capacité de levage. Avec les chariots élévateurs usagés de Toromont Manutention, vous pouvez facilement réduire et optimiser vos coûts d’opérations logistiques.",
      Desc: "Un bon chariot élévateur doit posséder des caractéristiques supérieures en termes de sécurité, de confort et de capacité de levage. Avec les chariots élévateurs usagés de Toromont Manutention, vous pouvez facilement réduire et optimiser vos coûts d’opérations logistiques. Nos chariots élévateurs électriques et à combustion sont robustes, durables, puissants et confortables, permettant de réduire vos temps d’arrêt pour une plus grande productivité et une meilleure rentabilité. Quel que soit l’industrie, nous avons une solution pour tous vos besoins en matière de manutention.",
      ExtraText: null,
    },
  },
  {
    CatCode: "Mining Equipment - Machines Pour Mines",
    CatCodeSlug: "mining-equipment-machines-pour-mines",
    EN: {
      Name: "Mining Equipment",
      Meta: "To navigate the notoriously rigorous terrain and harsh environment that is common to the mining industry, you need Jobsite Industrial Rental Services’ used mining equipment. Our selection of used mining equipment will optimize your operations by delivering value and heavy-duty performance.",
      Desc: (
        <>
          <h2>Optimize Your Operations with Our Used Mining Equipment </h2>
          <p>
            To navigate the notoriously rigorous terrain and harsh environment
            that is common to the mining industry, you need Jobsite Industrial
            Rental Services’s used mining equipment. Our selection of used
            mining equipment will optimize your operations by delivering value
            and heavy-duty performance. Our machines offer superb traction,
            rigidity and hydraulic efficiency with premium components and
            advanced technologies. Thanks to our commercial mining vehicles, you
            can move material swiftly and at lower cost.
          </p>
        </>
      ),
      ExtraText: (
        <>
          <h4>Most Advanced Lineup of Used Mining Equipment </h4>
          <p>
            Jobsite Industrial Rental Services carries the mining industry’s
            largest and most advanced lineup of used mining equipment for
            surface mining applications. Whether your mining site requires
            digging, drilling, hauling or moving, Jobsite Industrial Rental
            Services has the used mining equipment, loading tools and commercial
            mining vehicles needed to get the job done. Caterpillar’s used
            mining equipment is renowned for its efficiency, excellent traction
            and rigidity to help you move your materials faster. Contact Jobsite
            Industrial Rental Services today for more information about our used
            mining equipment!
          </p>
        </>
      ),
    },
    FR: {
      Name: "Équipements miniers",
      Meta: "Pour naviguer sur le terrain rigoureux et difficile qui est commun à l’industrie minière, il vous faut l’équipement minier usagé de Location d'outils industriels Jobsite. Notre sélection d’équipements miniers améliorera vos opérations en livrant une performance de longue durée.",
      Desc: "Pour naviguer sur le terrain rigoureux et difficile qui est commun à l’industrie minière, il vous faut l’équipement minier usagé de Location d'outils industriels Jobsite. Notre sélection d’équipements miniers améliorera vos opérations en livrant une performance de longue durée et une valeur supérieure. Nos machines sont dotées de technologies avancées et de composants de qualité qui fournissent une superbe traction, une plus grande durabilité et une puissance hydraulique exceptionnelle. Grâce à nos véhicules miniers commerciaux, vous pouvez transporter votre matériel plus rapidement à un coût moins élevé.",
      ExtraText: (
        <>
          <h4>La plus grande gamme d’équipement minier usagé</h4>
          <p>
            Location d'outils industriels Jobsite offre la plus grande sélection
            d’équipement minier usagé pour tous vos travaux miniers. Que votre
            chantier nécessite des travaux de terrassement, de forage ou de
            transport, l’équipement minier usagé de Location d'outils
            industriels Jobsite vous aidera à optimiser votre productivité.
            L’équipement d’exploitation minière d’occasion Caterpillar est
            réputé pour son efficacité, sa traction supérieure et sa rigidité,
            vous permettant de transporter vos matériaux plus rapidement.
            Contactez Jobsite Industrial Rental Services aujourd’hui pour
            obtenir plus d’information sur notre équipement minier usagé!
          </p>
        </>
      ),
    },
  },
  {
    CatCode: "Motor Graders - Niveleuses",
    CatCodeSlug: "motor-graders-niveleuses",
    EN: {
      Name: "Motor Graders",
      Meta: "Your fleet isn’t complete without a Jobsite Industrial Rental Services motor grader. Our used graders provide precise maneuverability in tight spaces and excellent traction on slopes and soft underfoot conditions.",
      Desc: (
        <>
          <h2>Jobsite Industrial Rental Services’ Used Motor Graders</h2>
          <p>
            Your fleet isn’t complete without a Jobsite Industrial Rental
            Services motor grader. Our used graders provide precise
            maneuverability in tight spaces and excellent traction on slopes and
            soft underfoot conditions. Motor graders can easily travel from
            point A to point B, and can handle multiple jobs in a variety of
            applications. Their invaluable, multi-faceted usage in road paving,
            construction, farming and snow removal applications makes them a
            truly versatile machine.
          </p>
          <h3>Used Motor Graders Currently for Sale: </h3>
        </>
      ),
      ExtraText: (
        <>
          <h4>Used Motor Graders to Flatten Any Jobsite</h4>
          <p>
            The Cat motor graders we carry are renowned for their controls,
            technology, hydraulics and comfortable cabs. Jobsite Industrial
            Rental Services carries used motor graders that range in power from
            179 HP to 535 HP with blade widths from 12 ft to 24 ft, designed to
            help you get the job done. They also work well on sloped or soft
            terrain and provide excellent maneuvering in small spaces. If you
            need a used motor grader for agricultural, construction, paving or
            snow removal applications, contact Jobsite Industrial Rental
            Services today!
          </p>
        </>
      ),
    },
    FR: {
      Name: "Niveleuses",
      Meta: "Votre flotte n’est pas complète sans une niveleuse usagée de Location d'outils industriels Jobsite. Nos niveleuses d’occasion à vendre fournissent une maniabilité précise dans les espaces restreints et une excellente traction sur les pentes et le sol à faible portance.",
      Desc: "Votre flotte n’est pas complète sans une niveleuse usagée de Location d'outils industriels Jobsite. Nos niveleuses d’occasion à vendre fournissent une maniabilité précise dans les espaces restreints et une excellente traction sur les pentes et le sol à faible portance. Les niveleuses sont complètement polyvalentes, car elles peuvent se déplacer facilement entre les chantiers et peuvent accomplir plusieurs travaux dans une grande variété d’applications, comme celles du pavage, de la construction, de l’agriculture et du déneigement.",
      ExtraText: (
        <>
          <h4>Maîtrisez le chantier avec nos niveleuses usagées</h4>
          <p>
            Nos niveleuses Cat sont réputées pour leur excellent contrôle, leurs
            technologies avancées, leurs systèmes hydrauliques supérieurs et
            leurs cabines ergonomiques. Les niveleuses d’occasion de Toromont
            Cat ont une puissance de 179 HP à 535 HP et une largeur de lame de
            12 pi à 14 pi, vous permettant d’accomplir vos projets avec plus
            d’efficacité. Elles offrent aussi une maniabilité accrue sur les
            pentes et dans les espaces étroits. Si vous avez besoin d’une
            niveleuse pour vos travaux d’agriculture, de construction, de pavage
            ou de déneigement, contactez Location d'outils industriels Jobsite
            aujourd’hui!{" "}
          </p>
        </>
      ),
    },
  },
  {
    CatCode: "Off-highway Trucks / Tractors - Tombereaux rigides / tracteurs",
    CatCodeSlug: "off-highway-trucks-tractors-tombereaux-rigides-tracteurs",
    EN: {
      Name: "Off-highway Trucks / Tractors",
      Meta: "Off highway trucks are specifically designed for the mining, quarry and construction industries, where challenging terrain and extreme environments are the norm. In these industries, durability, reliability and load capacity matter the most.",
      Desc: (
        <>
          <h2>Exceed Your Expectations with Our Used Off-Highway Trucks </h2>
          <p>
            Off highway trucks are specifically designed for the mining, quarry
            and construction industries, where challenging terrain and extreme
            environments are the norm. In these industries, durability,
            reliability and load capacity matter the most. Toromont’s used off
            highway trucks will exceed your expectations. Excellent hauling
            capabilities, maneuverability, safety and operating costs are just
            some of the outstanding features of our machines.
          </p>
        </>
      ),
      ExtraText: (
        <>
          <h4>Wide Range of Used Off-Highway Trucks and Tractors </h4>
          <p>
            Jobsite Industrial Rental Services carries a range of used
            off-highway trucks created specifically to handle mining,
            construction and quarry challenges. Our Caterpillar used off-highway
            trucks can carry payloads between 42.1 ton and 98.4 ton and travel
            more than 40 mph when fully loaded. Caterpillar’s used off-highway
            trucks are built to withstand a variety of environments and terrains
            and can be relied upon to get the job done when it matters most. If
            your next construction, mining or quarry project needs a used
            off-highway truck, contact Jobsite Industrial Rental Services today!{" "}
          </p>
        </>
      ),
    },
    FR: {
      Name: "Tombereaux rigides / tracteurs",
      Meta: "Les tombereaux rigides sont spécialement conçus pour les industries minières où le terrain est habituellement difficile et les environnements sont extrêmes. Dans ces types d’industries, la durabilité, la fiabilité et la capacité de charge sont d’une grande importance.",
      Desc: "Les tombereaux rigides sont spécialement conçus pour les industries minières où le terrain est habituellement difficile et les environnements sont extrêmes. Dans ces types d’industries, la durabilité, la fiabilité et la capacité de charge sont d’une grande importance. Les tombereaux et les tracteurs rigides usagés de Location d'outils industriels Jobsite sauront dépasser vos atteintes, car ils sont fiables, sécuritaires et économiques, et offrent d’excellentes capacités de transport.",
      ExtraText: (
        <>
          <h4>Une grande gamme de tombereaux rigides et de tracteurs usagés</h4>
          <p>
            Location d'outils industriels Jobsite offre une gamme de tombereaux
            rigides conçus spécialement pour les travaux miniers, de
            construction et de carrière. Nos tombereaux rigides Caterpillar
            peuvent transporter des charges entre 42,1 tonnes et 98,4 tonnes et
            parcourir des distances de plus de 40 mph. Les tombereaux rigides
            usagés Caterpillar sont extrêmement fiables, car ils sont construits
            pour résister à une variété d’environnements et de terrains. Si
            votre prochain projet minier, de construction ou de carrière
            nécessite un tombereau rigide, contactez Location d'outils
            industriels Jobsite aujourd’hui!
          </p>
        </>
      ),
    },
  },
  {
    CatCode: "On-highway Trucks - Camions Routiers",
    CatCodeSlug: "on-highway-trucks-camions-routiers",
    EN: {
      Name: "On-highway Trucks",
      Meta: "Designed for the long haul, our premium selection of used on-highway trucks will help you transport today’s heavy loads with ease and efficiency.",
      Desc: "Our premium selection of used on-highway trucks will help you transport today’s heavy loads with ease and efficiency. Designed for the long haul, our used transport trucks feature an ergonomic cab that delivers superior comfort, a powerful, fuel-efficient engine that can handle long distances, a rugged, industrial build for long-lasting performance, and technologically advanced features that will optimize the drive, enhancing any hauling application for superior productivity and results at top value.",
      ExtraText: null,
    },
    FR: {
      Name: "Camions Routiers",
      Meta: "Conçue pour le transport de charges lourdes, notre sélection de camions routiers usagés vous permettra d’effectuer vos applications de transport avec facilité et efficacité.",
      Desc: "Notre sélection de camions routiers usagés vous permettra d’effectuer vos applications de transport avec facilité et efficacité. Conçus pour le transport de charges lourdes, nos camions de transport usagés sont équipés d'une cabine ergonomique offrant un confort supérieur, d'un moteur puissant et faible en carburant, d'une construction robuste pour une durabilité accrue, et de technologies avancées qui optimisent la conduite et la performance, fournissant une productivité et des résultats supérieurs.",
      ExtraText: null,
    },
  },
  {
    CatCode: "Paving Products - Matériel Routier",
    CatCodeSlug: "paving-products-materiel-routier",
    EN: {
      Name: "Paving Products",
      Meta: "Our range of used asphalt pavers and rollers will provide economical and versatile solutions for all types of paving needs, from airport and highway projects to small housing developments.",
      Desc: (
        <>
          <h2>Used Asphalt Pavers for all Your Paving Needs </h2>
          <p>
            Our range of used asphalt pavers and rollers will provide economical
            and versatile solutions for all types of paving needs, from airport
            and highway projects to small housing developments. Jobsite
            Industrial Rental Services’s paving equipment is the ideal choice
            for demanding applications that require fuel efficiency, comfortable
            operating conditions, impressive mobility and superior
            functionality. With our selection of used wheel and steel track
            pavers, you can take any job to the next level.
          </p>
          <h3>Used Asphalt Pavers Currently for Sale: </h3>
        </>
      ),
      ExtraText: (
        <>
          <h4>Our Selection of Used Paving Products </h4>
          <p>
            Jobsite Industrial Rental Services carries a large selection of used
            paving products – including wheel and track asphalt pavers and
            screeds – for sale or rental. Caterpillar’s used paving products are
            known for their mobility, fuel efficiency and comfortable cab
            conditions for operators. Our asphalt pavers are also renowned for
            their high horsepower, ranging in gross power from 46 HP to 225 HP.
            Whatever your paving needs, Jobsite Industrial Rental Services has
            the used paving products to get your job done. Contact us for more
            information or a quote today!
          </p>
        </>
      ),
    },
    FR: {
      Name: "Produits de pavage",
      Meta: "Notre gamme de finisseurs et de rouleaux usagés fournit des solutions économiques et polyvalentes pour tous besoins en matière de pavage, que ce soit pour des projets d’autoroute, d’aéroport ou des développements résidentiels.",
      Desc: "Notre gamme de finisseurs et de rouleaux usagés fournit des solutions économiques et polyvalentes pour tous besoins en matière de pavage, que ce soit pour des projets d’autoroute, d’aéroport ou de développement résidentiel. L’équipement de pavage de Location d'outils industriels Jobsite est le choix idéal pour les applications exigeantes nécessitant une faible consommation de carburant, une opération confortable, une mobilité impressionnante et une fonctionnalité supérieure. Avec notre sélection de finisseurs sur roues ou sur chaînes, votre travail peut atteindre des nouveaux sommets.",
      ExtraText: (
        <>
          <h4>Notre sélection de produits de pavage usagés</h4>
          <p>
            Location d'outils industriels Jobsite offre une vaste sélection de
            produits de pavage usagés à vendre ou pour location. Notre gamme de
            finisseurs et de rouleaux Caterpillar sont renommés pour leur
            mobilité, leur efficacité en carburant et leurs cabines
            confortables. Nos finisseurs sont aussi réputés pour leur puissance
            élevée, allant de 46 HP à 225 HP. Quels que soient vos besoins, vous
            pouvez compter sur Location d'outils industriels Jobsite pour les
            produits de pavage les plus efficaces. Contactez-nous aujourd’hui
            pour demander une soumission ou pour obtenir plus d’information!{" "}
          </p>
        </>
      ),
    },
  },
  {
    CatCode: "Skid Steer Loaders / ASV - Chargeurs Compacts rigides",
    CatCodeSlug: "skid-steer-loaders-asv-chargeurs-compacts-rigides",
    EN: {
      Name: "Skid Steer Loaders",
      Meta: "Skid steer loaders are highly adaptable machines. With the use of different attachments, multi terrain loaders can handle a wide range of jobs from snow removal and landscaping to debris removal.",
      Desc: (
        <>
          <h2>Jobsite Industrial Rental Services’ Used Skid Steer Loaders</h2>
          <p>
            Skid Steer Loaders are highly adaptable machines. With the use of
            different attachments, skid steer loaders can handle a wide range of
            jobs from snow removal and landscaping to debris removal. Jobsite
            Industrial Rental Services’ used skid steer loaders are primed for
            digging, grading, pushing or loading in almost any kind of
            environment.
          </p>
          <h3>Used Skid Steer Loaders Currently for Sale: </h3>
        </>
      ),
      ExtraText: (
        <>
          <h4>Used Skid Steer Loaders to Get the Job Done </h4>
          <p>
            Skid steer loaders are strong, powerful machines with arms that can
            be attached to a variety of Cat attachments for different
            applications. Jobsite Industrial Rental Services carries several
            models of used skid steer loaders that range in power from 65.8 HP
            to 106 HP and have an operating capacity of 1550 lb to 3650 lb. Our
            used Caterpillar skid steer loaders are known worldwide for their
            durability, reliability, efficiency and simple controls. Jobsite
            Industrial Rental Services’s used steer loaders are designed and
            built to help you get the job done, so contact us for more
            information today!
          </p>
        </>
      ),
    },
    FR: {
      Name: "Chargeurs Compacts rigides",
      Meta: "Les chargeurs compacts rigides sont très adaptables. En utilisant plusieurs accessoires différents, les chargeurs tout terrain peuvent accomplir un large éventail de travaux comme le déneigement, l’aménagement paysager et l’élimination des débris.",
      Desc: "Les chargeurs compacts rigides sont très adaptables. En utilisant plusieurs accessoires différents, les chargeurs tout terrain peuvent accomplir un large éventail de travaux comme le déneigement, l’aménagement paysager et l’élimination des débris. Les chargeurs compacts rigides usagés de Location d'outils industriels Jobsite sont prêts à creuser, à niveler, à pousser ou à transporter dans n’importe quel type d’environnement. Nos petits chargeurs sont idéals pour les travaux d’intérieur ou les espaces étroits, tandis que nos gros chargeurs sont parfaits pour les grands travaux d’extérieur tels que la démolition et l’exploitation forestière.",
      ExtraText: (
        <>
          <h4>
            Les chargeurs compacts rigides usagés pour un travail bien fait
          </h4>
          <p>
            Les chargeurs compacts rigides sont des machines robustes et
            puissantes avec des bras qui peuvent être fixés à une variété
            d’accessoires Cat pour différentes applications. Jobsite Industrial
            Rental Services offre plusieurs modèles de chargeurs compacts
            rigides avec une puissance de 65,8 HP à 106 HP et une capacité de
            chargement de 1 550 lb à 3 650 lb. Nos chargeurs compacts rigides
            Caterpillar sont connus dans le monde entier pour leur durabilité,
            leur fiabilité, leur efficacité et leurs technologies de pointe. Les
            chargeurs compacts rigides d’occasion de Location d'outils
            industriels Jobsite sont conçus pour faciliter tout type de travail,
            alors contactez-nous aujourd’hui pour obtenir plus d’information!
          </p>
        </>
      ),
    },
  },
  {
    CatCode: "Telehandlers - Chargeurs à Bras Télescopique",
    CatCodeSlug: "telehandlers-chargeurs-a-bras-telescopique",
    EN: {
      Name: "Telehandlers",
      Meta: "With Jobsite Industrial Rental Services’ used telehandlers, nothing is out of reach. Our line of telescopic lifts add unmatched versatility and ease of handling to any industry application.",
      Desc: (
        <>
          <h2>
            Jobsite Industrial Rental Services’ Selection of Used Telehandlers{" "}
          </h2>
          <p>
            Jobsite Industrial Rental Services’ selection of Used Telehandlers
            With Jobsite Industrial Rental Services’ used telehandlers, nothing
            is out of reach. Our line of telescopic lifts add unmatched
            versatility and ease of handling to any industry application.
            Telehandlers can operate where forklifts and conventional equipment
            cannot, offering extra stability on steep or rough terrain,
            durability for heavy load handling, and optimal lift and reach
            capabilities for hard-hitting results.
          </p>
          <h3>Used Telehandlers Currently for Sale: </h3>
        </>
      ),
      ExtraText: (
        <>
          <h4>Used Telehandlers for Any Job </h4>
          <p>
            Jobsite Industrial Rental Services’ used telehandlers by Caterpillar
            are easily maneuverable and versatile for use in a variety of
            industries. The used telehandlers we carry are known for their reach
            and lift capabilities and have load capacities ranging from 5,500 lb
            to 12,000 lb. Our used telehandlers are specifically built and
            designed to operate in challenging terrains where conventional heavy
            equipment would struggle. If your job site or next project could
            benefit from a durable, powerful and reliable telehandler, contact
            Jobsite Industrial Rental Services for more information today!
          </p>
        </>
      ),
    },
    FR: {
      Name: "Chargeurs à Bras Télescopique",
      Meta: "Avec les chargeurs à bras télescopique usagés de Location d'outils industriels Jobsite, le ciel est à votre portée. Notre gamme de chargeurs à bras télescopique ajoute une polyvalence et une facilité de manutention à n’importe quelle application.",
      Desc: "Avec les chargeurs à bras télescopique usagés de Location d'outils industriels Jobsite, le ciel est à votre portée. Notre gamme de chargeurs à bras télescopique ajoute une polyvalence et une facilité de manutention à n’importe quelle application. Les chargeurs à bras télescopique peuvent travailler là où les chariots élévateurs et l’équipement conventionnel ne peuvent pas, fournissant une capacité de levage impressionnante, une superbe stabilité sur le terrain escarpé ou difficile, et une bonne durabilité pour la manutention de charges lourdes.",
      ExtraText: (
        <>
          <h4>Des chargeurs à bras télescopique pour tout type de travail</h4>
          <p>
            Les chargeurs à bras télescopique Caterpillar sont conçus pour une
            variété d’industries car ils sont polyvalents et faciles à
            manœuvrer. Nos chargeurs à bras télescopique sont connus pour leur
            capacité de chargement supérieure. Ils sont capables de transporter
            des charges allant de 5 500 lb à 12 000 lb. Les chargeurs à bras
            télescopique de Location d'outils industriels Jobsite sont
            spécialement conçus pour les terrains et les travaux rigoureux. Si
            votre prochain projet ou chantier nécessite un chargeur à bras
            télescopique fiable, durable et puissant, contactez Location
            d'outils industriels Jobsite aujourd’hui pour obtenir plus
            d’information!
          </p>
        </>
      ),
    },
  },
  {
    CatCode: "Track Type Loaders / Crawlers - Chargeurs Sur Chaines",
    CatCodeSlug: "track-type-loaders-crawlers-chargeurs-sur-chaines",
    EN: {
      Name: "Track Type Loaders / Crawlers",
      Meta: "When it comes to top loading and digging performance on the job site, nothing  beats our used track type loaders/crawlers.",
      Desc: (
        <>
          <h2>
            Reach Maximum Performance with Our Used Track Type Loaders and
            Crawlers{" "}
          </h2>
          <p>
            When it comes to maximum loading and digging performance on the job
            site, nothing beats our used track type loaders/crawlers. Toromont
            Cat tracked loaders are made to handle the most challenging
            terrains, offering unrivaled power, versatility and productivity
            thanks to their uncanny ability to dig, load and excavate in some of
            roughest and most stringent ground work applications such as mining,
            quarry and construction.
          </p>
          <h3>Used Track Type Loaders and Crawlers Currently for Sale: </h3>
        </>
      ),
      ExtraText: (
        <>
          <h4>
            Jobsite Industrial Rental Services’ Selection of Used Track Type
            Loaders and Crawlers{" "}
          </h4>
          <p>
            Toromont carries several models of used track type loaders and
            crawlers with high loading capacities and the ability to handle some
            of the world’s most challenging terrains. Our Caterpillar used track
            type loaders/crawlers range in flywheel power from 154 HP to 275 HP
            and are able to perform digging, loading and excavation tasks in
            some of the toughest industries. If your construction, mining or
            quarry site could benefit from a heavy-duty used track type
            loader/crawler, contact Jobsite Industrial Rental Services today for
            more information about our available models.
          </p>
        </>
      ),
    },
    FR: {
      Name: "Chargeurs Sur Chaines",
      Meta: "Nos chargeurs sur chaînes usagés offrent une puissance de creusement et de chargement supérieure sur le chantier.",
      Desc: "Nos chargeurs sur chaînes usagés offrent une puissance de creusement et de chargement supérieure sur le chantier. Les chargeurs sur chaînes de Location d'outils industriels Jobsite sont conçus pour maîtriser les terrains les plus rigoureux. Ils fournissent une puissance et une polyvalence inégalées grâce à leur étonnante capacité à creuser et à charger dans les applications les plus difficiles telles que l'exploitation minière, la carrière et la construction.",
      ExtraText: (
        <>
          <h4>
            La sélection de chargeurs sur chaines usagés de Jobsite Industrial
            Rental Services
          </h4>
          <p>
            Location d'outils industriels Jobsite offre plusieurs modèles de
            chargeurs sur chaines usagés avec une capacité de chargement élevée.
            Nos chargeurs sur chaines d’occasion Caterpillar fournissent une
            puissance d’inertie de 154 HP à 275 HP et sont capables d’effectuer
            des travaux de creusement, de chargement et d’excavation dans les
            industries les plus rigoureuses. Si votre chantier minier, de
            construction ou de carrière nécessite un chargeur sur chaines usagé
            robuste, contactez Location d'outils industriels Jobsite aujourd’hui
            pour obtenir plus d’information sur nos modèles disponibles.
          </p>
        </>
      ),
    },
  },
  {
    CatCode: "Track Type Tractors / Dozers - Tracteurs Sur Chaines",
    CatCodeSlug: "track-type-tractors-dozers-tracteurs-sur-chaines",
    EN: {
      Name: "Track Type Tractors / Dozers",
      Meta: "Dozers are a force to be reckoned with when it comes to achieving peak mobility on difficult terrain. Our used track type tractors deliver earth-moving power thanks to their excellent tractive force and maneuverability.",
      Desc: (
        <>
          <h2>Our Used Track Type Tractors and Dozers </h2>
          <p>
            Dozers are a force to be reckoned with when it comes to achieving
            peak mobility on difficult terrain. Our used track type tractors
            deliver earth-moving power thanks to their excellent tractive force
            and maneuverability. Used on various job sites, such as farms,
            mines, quarries and construction, track loaders can effectively push
            dense soil, sand, rubble and other materials, bringing a high level
            of productivity and performance to any type of application.
          </p>
          <h3>Used Track Type Tractors and Dozers Currently for Sale: </h3>
        </>
      ),
      ExtraText: (
        <>
          <h4>Wide Range of Used Track Type Tractors and Used Dozers </h4>
          <p>
            Jobsite Industrial Rental Services has a range of Caterpillar’s used
            small, medium, large and waste handling dozers available to handle
            some of the toughest projects imaginable. Our fleet of used track
            type tractors and dozers is well-known for their advanced
            technologies, maneuverability, reliability and safety features that
            help them to outperform other bulldozers on any job site. Whether
            you need a used track type tractor or dozer for your next
            agricultural, construction, mining or quarry project, Jobsite
            Industrial Rental Services can help you find the right for your
            needs. Contact Jobsite Industrial Rental Services today for more
            information about our selection of used track type tractors or
            dozers!
          </p>
        </>
      ),
    },
    FR: {
      Name: "Tracteurs Sur Chaines",
      Meta: "En matière de performance et de mobilité sur les terrains difficiles, rien ne bat les bulldozers usagés à vendre de Location d'outils industriels Jobsite. Grâce à leur maniabilité et à leur superbe capacité de traction, nos dozers usagés offrent une puissance qui fait secouer la terre.",
      Desc: "En matière de performance et de mobilité sur les terrains difficiles, rien ne bat les bulldozers usagés à vendre de Location d'outils industriels Jobsite. Grâce à leur maniabilité et à leur superbe capacité de traction, nos dozers usagés offrent une puissance qui fait secouer la terre. Utilisés sur une variété de chantiers tels que les fermes, les mines, les carrières et la construction, les tracteurs sur chaines peuvent efficacement pousser de la terre, du sable et d’autres matériaux pour fournir un haut niveau de productivité et de performance dans toutes sortes d’applications.",
      ExtraText: (
        <>
          <h4>Grande gamme de tracteurs sur chaines usagés</h4>
          <p>
            Location d'outils industriels Jobsite offre une sélection de petits,
            moyens et grands tracteurs sur chaines usagés Caterpillar pour tous
            types de travaux. Nos tracteurs sur chaines sont renommés pour leurs
            technologies de pointe, leur maniabilité, leur fiabilité et leur
            sécurité. Nos tracteurs sur chaines d’occasion fournissent une
            performance optimale sur le chantier. Si vous avez besoin d’un
            tracteur sur chaines pour votre prochain projet minier,
            d’agriculture, de construction ou de carrière, Location d'outils
            industriels Jobsite peut vous aider à trouver la machine la mieux
            adaptée à vos besoins. Contactez Location d'outils industriels
            Jobsite aujourd’hui pour obtenir plus d’information sur notre
            sélection de tracteurs sur chaines usagés!
          </p>
        </>
      ),
    },
  },
  {
    CatCode:
      "Wheel Loaders / Integrated Toolcarriers - Chargeurs Sur Pneus / Chargeurs Industriels",
    CatCodeSlug:
      "wheel-loaders-integrated-toolcarriers-chargeurs-sur-pneus-chargeurs-industriels",
    EN: {
      Name: "Wheel Loaders / Integrated Toolcarriers",
      Meta: "When choosing the right wheel loader, configuration is key. With a wide range of loaders from Jobsite Industrial Rental Services, selecting the best type of equipment for each specific application is fast and simple. Our wheel loaders deliver excellent fill factors, improved cycle times and better visibility.",
      Desc: (
        <>
          <h2>Wide Selection of Used Cat Wheel Loaders</h2>
          <p>
            With Jobsite Industrial Rental Services’ range of Used Compact Wheel
            Loaders selecting the best type of equipment for each specific
            application is fast and simple. When you buy used compact wheel
            loaders from us, you will equip your fleet with equipment that
            delivers excellent fill factors, improved cycle times and better
            visibility. From asphalt and dirt, to gravel and snow, our used
            compact wheel loaders can handle any material on the job site with
            precision, safety and fuel efficiency. Our used compact wheel
            loaders are important assets on any job site, helping perform daily
            tasks such as landscaping, construction, forestry and agriculture
            projects with enhanced capabilities that increase efficiency and
            lower operating costs.
          </p>
          <h3>Our used wheel loaders currently for sale: </h3>
        </>
      ),
      ExtraText: (
        <>
          <h4>Used Wheel Loaders for Any Job</h4>
          <p>
            Our used wheel loaders can help maximize your budget. Our machines
            are always inspected for top quality assurance, so you know you’re
            getting the best performance, reliability and durability at the
            absolute best value. Cat wheel loaders are outfitted with innovative
            technologies that offer high performance with outstanding
            versatility. Standard features deliver a significant increase in
            lift capacity and travel speed, allowing operators to complete their
            jobs in a shorter amount of time. Jobsite Industrial Rental Services
            used wheel loaders for sale are also outfitted with premium
            ergonomic cabs and options such as engine and ground speed control,
            which provide customizable control when operating work tools.
          </p>
        </>
      ),
    },
    FR: {
      Name: "Chargeurs Sur Pneus / Chargeurs Industriels",
      Meta: "Pour choisir le bon chargeur sur pneus, il faut considérer la configuration. Avec la gamme de chargeurs sur roues usagés de Location d'outils industriels Jobsite, il est simple et facile de sélectionner le meilleur équipement pour chaque application.",
      Desc: "Pour choisir le bon chargeur sur pneus, il faut considérer la configuration. Avec la gamme de chargeurs sur roues usagés de Location d'outils industriels Jobsite, il est simple et facile de sélectionner le meilleur équipement pour chaque application. Nos chargeurs sur roues à vendre améliorent les temps de cycles, la visibilité et les facteurs de remplissage. Nos chargeurs peuvent gérer le transport et la manipulation de toutes sortes de matériaux, comme l’asphalte, la terre, le gravier et la neige, avec précision, sécurité et une faible consommation de carburant. Utilisés pour les travaux d’aménagement paysager, de construction, d’exploitation forestière, de gestion de déchets, de mine ou d’agriculture, et équipés d’une variété d’outils de travail et de technologies, nos chargeurs sur pneus d’occasion peuvent accomplir n’importe quel travail tout en optimisant le rendement et les performances opérationnelles.",
      ExtraText: (
        <>
          <h4>Des chargeurs sur pneus pour tout type de travail</h4>
          <p>
            Nos chargeurs sur pneus vous permettent de maximiser votre budget.
            Nos machines sont toujours inspectées pour une assurance de qualité
            supérieure, ce qui vous permet d’obtenir la meilleure performance,
            fiabilité et durabilité à la meilleure valeur. Les chargeurs sur
            pneus Cat sont équipés de technologies avancées qui offrent une
            performance élevée et une polyvalence exceptionnelle. Les
            technologies intégrées d’usine augmentent nettement la puissance de
            levage et la vitesse de voyage, permettant aux opérateurs de
            compléter leur travail plus rapidement. Les chargeurs sur pneus
            d’occasion à vendre de Location d'outils industriels Jobsite sont
            aussi équipés de cabines ergonomiques et d’options telles que le
            contrôle du moteur et de la vitesse au sol, fournissant un contrôle
            personnalisé lors de l’utilisation d’outils de travail.
          </p>
        </>
      ),
    },
  },
  {
    CatCode: "Work Tools - Outils De Travail",
    CatCodeSlug: "work-tools-outils-de-travail",
    EN: {
      Name: "Work Tools",
      Meta: "The right tools will do the right job. Jobsite Industrial Rental Services offers a wide assortment of used work tools suited for all types of applications, from landscaping to snow removal.",
      Desc: "The right tools will do the right job. Jobsite Industrial Rental Services offers a wide assortment of used work tools suited for all types of applications, from landscaping to snow removal. We carry used buckets, augers and forks, as well as breakers, compactors and snow removal. Getting your hands on our cost-friendly work tools will ensure flexibility and peak performance on the job site.",
      ExtraText: null,
    },
    FR: {
      Name: "Outils De Travail",
      Meta: "Les bons outils font un bon travail. Location d'outils industriels Jobsite offre un grand assortiment d’outils de travail usagés qui sont adaptés à plusieurs types d’applications comme le déneigement et le travail forestier.",
      Desc: "Les bons outils font un bon travail. Location d'outils industriels Jobsite offre un grand assortiment d’outils de travail usagés qui sont adaptés à plusieurs types d’applications comme le déneigement et le travail forestier. Nous offrons des godets, des têtes d’abattage et des fourches ainsi que des outils pour concasseurs, compacteurs et équipement de déneigement. Nos outils de travail à prix budgétaires vous aideront à atteindre une meilleure performance et flexibilité sur le chantier.",
      ExtraText: null,
    },
  },
  {
    CatCode: "Power Systems - Production d'énergie",
    CatCodeSlug: "power-systems-production-d-energie",
    EN: {
      Name: "Generators",
      Meta: "Jobsite Industrial Rental Services carries a wide selection of used commercial and industrial generators. Our used gas and diesel generators are built to world-class standards, for high efficiency and low fuel consumption. Contact us today for more information!",
      Desc: (
        <>
          <p>
            Generators provide power for countless agricultural, construction
            and mining projects across Canada. They are also often required to
            power complex business data centers and hospitals. Jobsite
            Industrial Rental Services carries a large selection of used
            generators for commercial and industrial applications that range in
            power from 40 kW to 2,000 kW. We guarantee that our Caterpillar used
            generators are load tested and regularly serviced to ensure that you
            can use your generator immediately after purchase. Whether you need
            a used generator for your job site or business, contact Jobsite
            Industrial Rental Services today for more information about our used
            generator range.
          </p>
          <h3>Used Generators Currently for Sale: </h3>
        </>
      ),
      ExtraText: (
        <>
          <h4>Used Commercial and Industrial Generators</h4>
          <p>
            Jobsite Industrial Rental Services carries a wide selection of used
            commercial and industrial generators. Our used gas and diesel
            generators are built to world-class standards, for high efficiency
            and low fuel consumption. Contact us today for more information!
          </p>
        </>
      ),
    },
    FR: {
      Name: "Groupes électrogènes",
      Meta: "Location d'outils industriels Jobsite offre une large sélection de groupes électrogènes commerciaux et industriels. Nos groupes électrogènes au gaz et au diesel sont conçus pour fournir une performance et efficacité supérieures ainsi qu’une plus grande économie en carburant. Contactez-nous aujourd’hui pour obtenir plus d’information!",
      Desc: (
        <>
          <p>
            Les groupes électrogènes alimentent une multitude de projets
            miniers, d’agriculture et de construction à travers le Canada. Ils
            sont aussi souvent utilisés pour alimenter des centres hospitaliers
            et des centres complexes de données d’entreprise. Jobsite Industrial
            Rental Services offre une large sélection de groupes électrogènes
            usagés, qui livrent une puissance de 40 kW à 2 000 kW, pour des
            applications commerciales et industrielles. Nous garantissons que
            les groupes électrogènes usagés Caterpillar sont testés en charge et
            régulièrement entretenus afin d’être pouvoir utilisé immédiatement
            après leur achat. Si vous avez besoin d’un groupe électrogène
            d’occasion pour votre chantier ou votre entreprise, veuillez
            contacter Location d'outils industriels Jobsite aujourd’hui pour
            obtenir plus d’information sur notre sélection de groupes
            électrogènes.
          </p>
          <h3>Groupes électrogènes actuellement en vente:</h3>
        </>
      ),
      ExtraText: (
        <>
          <h4>Groupes électrogènes commerciaux et industriels usagés</h4>
          <p>
            Location d'outils industriels Jobsite offre une large sélection de
            groupes électrogènes commerciaux et industriels. Nos groupes
            électrogènes au gaz et au diesel sont conçus pour fournir une
            performance et efficacité supérieures ainsi qu’une plus grande
            économie en carburant. Contactez-nous aujourd’hui pour obtenir plus
            d’information!
          </p>
        </>
      ),
    },
  },
  {
    CatCode: "gens",
    CatCodeSlug: "gens",
    EN: {
      Name: "Stationary Generator Sets",
      Meta: "Jobsite Industrial Rental Services carries a wide selection of used commercial and industrial generators. Our used gas and diesel generators are built to world-class standards, for high efficiency and low fuel consumption. Contact us today for more information!",
      Desc: (
        <>
          <p>
            Generators provide power for countless agricultural, construction
            and mining projects across Canada. They are also often required to
            power complex business data centers and hospitals. Jobsite
            Industrial Rental Services carries a large selection of used
            generators for commercial and industrial applications that range in
            power from 40 kW to 2,000 kW. We guarantee that our Caterpillar used
            generators are load tested and regularly serviced to ensure that you
            can use your generator immediately after purchase. Whether you need
            a used generator for your job site or business, contact Jobsite
            Industrial Rental Services today for more information about our used
            generator range.
          </p>
          <h3>Used Generators Currently for Sale: </h3>
        </>
      ),
      ExtraText: (
        <>
          <h4>Used Commercial and Industrial Generators</h4>
          <p>
            Jobsite Industrial Rental Services carries a wide selection of used
            commercial and industrial generators. Our used gas and diesel
            generators are built to world-class standards, for high efficiency
            and low fuel consumption. Contact us today for more information!
          </p>
        </>
      ),
    },
    FR: {
      Name: "GROUPES ÉLECTROGÈNES STATIONNAIRES",
      Meta: "Location d'outils industriels Jobsite offre une large sélection de groupes électrogènes commerciaux et industriels. Nos groupes électrogènes au gaz et au diesel sont conçus pour fournir une performance et efficacité supérieures ainsi qu’une plus grande économie en carburant. Contactez-nous aujourd’hui pour obtenir plus d’information!",
      Desc: (
        <>
          <p>
            Les groupes électrogènes alimentent une multitude de projets
            miniers, d’agriculture et de construction à travers le Canada. Ils
            sont aussi souvent utilisés pour alimenter des centres hospitaliers
            et des centres complexes de données d’entreprise. Location d'outils
            industriels Jobsite offre une large sélection de groupes
            électrogènes usagés, qui livrent une puissance de 40 kW à 2 000 kW,
            pour des applications commerciales et industrielles. Nous
            garantissons que les groupes électrogènes usagés Caterpillar sont
            testés en charge et régulièrement entretenus afin d’être pouvoir
            utilisé immédiatement après leur achat. Si vous avez besoin d’un
            groupe électrogène d’occasion pour votre chantier ou votre
            entreprise, veuillez contacter Location d'outils industriels Jobsite
            aujourd’hui pour obtenir plus d’information sur notre sélection de
            groupes électrogènes.
          </p>
          <h3>Groupes électrogènes actuellement en vente:</h3>
        </>
      ),
      ExtraText: (
        <>
          <h4>Groupes électrogènes commerciaux et industriels usagés</h4>
          <p>
            Location d'outils industriels Jobsite offre une large sélection de
            groupes électrogènes commerciaux et industriels. Nos groupes
            électrogènes au gaz et au diesel sont conçus pour fournir une
            performance et efficacité supérieures ainsi qu’une plus grande
            économie en carburant. Contactez-nous aujourd’hui pour obtenir plus
            d’information!
          </p>
        </>
      ),
    },
  },
  {
    CatCode: "inen",
    CatCodeSlug: "inen",
    EN: {
      Name: "Industrial Engines",
      Meta: "Jobsite Industrial Rental Services carries a wide selection of used commercial and industrial generators. Our used gas and diesel generators are built to world-class standards, for high efficiency and low fuel consumption. Contact us today for more information!",
      Desc: (
        <>
          <p>
            Generators provide power for countless agricultural, construction
            and mining projects across Canada. They are also often required to
            power complex business data centers and hospitals. Jobsite
            Industrial Rental Services carries a large selection of used
            generators for commercial and industrial applications that range in
            power from 40 kW to 2,000 kW. We guarantee that our Caterpillar used
            generators are load tested and regularly serviced to ensure that you
            can use your generator immediately after purchase. Whether you need
            a used generator for your job site or business, contact Jobsite
            Industrial Rental Services today for more information about our used
            generator range.
          </p>
          <h3>Used Generators Currently for Sale: </h3>
        </>
      ),
      ExtraText: (
        <>
          <h4>Used Commercial and Industrial Generators</h4>
          <p>
            Jobsite Industrial Rental Services carries a wide selection of used
            commercial and industrial generators. Our used gas and diesel
            generators are built to world-class standards, for high efficiency
            and low fuel consumption. Contact us today for more information!
          </p>
        </>
      ),
    },
    FR: {
      Name: "MOTEURS INDUSTRIELS",
      Meta: "Location d'outils industriels Jobsite offre une large sélection de groupes électrogènes commerciaux et industriels. Nos groupes électrogènes au gaz et au diesel sont conçus pour fournir une performance et efficacité supérieures ainsi qu’une plus grande économie en carburant. Contactez-nous aujourd’hui pour obtenir plus d’information!",
      Desc: (
        <>
          <p>
            Les groupes électrogènes alimentent une multitude de projets
            miniers, d’agriculture et de construction à travers le Canada. Ils
            sont aussi souvent utilisés pour alimenter des centres hospitaliers
            et des centres complexes de données d’entreprise. Location d'outils
            industriels Jobsite offre une large sélection de groupes
            électrogènes usagés, qui livrent une puissance de 40 kW à 2 000 kW,
            pour des applications commerciales et industrielles. Nous
            garantissons que les groupes électrogènes usagés Caterpillar sont
            testés en charge et régulièrement entretenus afin d’être pouvoir
            utilisé immédiatement après leur achat. Si vous avez besoin d’un
            groupe électrogène d’occasion pour votre chantier ou votre
            entreprise, veuillez contacter Location d'outils industriels Jobsite
            aujourd’hui pour obtenir plus d’information sur notre sélection de
            groupes électrogènes.
          </p>
          <h3>Groupes électrogènes actuellement en vente:</h3>
        </>
      ),
      ExtraText: (
        <>
          <h4>Groupes électrogènes commerciaux et industriels usagés</h4>
          <p>
            Location d'outils industriels Jobsite offre une large sélection de
            groupes électrogènes commerciaux et industriels. Nos groupes
            électrogènes au gaz et au diesel sont conçus pour fournir une
            performance et efficacité supérieures ainsi qu’une plus grande
            économie en carburant. Contactez-nous aujourd’hui pour obtenir plus
            d’information!
          </p>
        </>
      ),
    },
  },
  {
    CatCode: "genm",
    CatCodeSlug: "genm",
    EN: {
      Name: "Mobile Generator Sets",
      Meta: "Jobsite Industrial Rental Services carries a wide selection of used commercial and industrial generators. Our used gas and diesel generators are built to world-class standards, for high efficiency and low fuel consumption. Contact us today for more information!",
      Desc: (
        <>
          <p>
            Generators provide power for countless agricultural, construction
            and mining projects across Canada. They are also often required to
            power complex business data centers and hospitals. Jobsite
            Industrial Rental Services carries a large selection of used
            generators for commercial and industrial applications that range in
            power from 40 kW to 2,000 kW. We guarantee that our Caterpillar used
            generators are load tested and regularly serviced to ensure that you
            can use your generator immediately after purchase. Whether you need
            a used generator for your job site or business, contact Jobsite
            Industrial Rental Services today for more information about our used
            generator range.
          </p>
          <h3>Used Generators Currently for Sale: </h3>
        </>
      ),
      ExtraText: (
        <>
          <h4>Used Commercial and Industrial Generators</h4>
          <p>
            Jobsite Industrial Rental Services carries a wide selection of used
            commercial and industrial generators. Our used gas and diesel
            generators are built to world-class standards, for high efficiency
            and low fuel consumption. Contact us today for more information!
          </p>
        </>
      ),
    },
    FR: {
      Name: "GROUPES ÉLECTROGÈNES MOBILES (2)",
      Meta: "Location d'outils industriels Jobsite offre une large sélection de groupes électrogènes commerciaux et industriels. Nos groupes électrogènes au gaz et au diesel sont conçus pour fournir une performance et efficacité supérieures ainsi qu’une plus grande économie en carburant. Contactez-nous aujourd’hui pour obtenir plus d’information!",
      Desc: (
        <>
          <p>
            Les groupes électrogènes alimentent une multitude de projets
            miniers, d’agriculture et de construction à travers le Canada. Ils
            sont aussi souvent utilisés pour alimenter des centres hospitaliers
            et des centres complexes de données d’entreprise. Location d'outils
            industriels Jobsite offre une large sélection de groupes
            électrogènes usagés, qui livrent une puissance de 40 kW à 2 000 kW,
            pour des applications commerciales et industrielles. Nous
            garantissons que les groupes électrogènes usagés Caterpillar sont
            testés en charge et régulièrement entretenus afin d’être pouvoir
            utilisé immédiatement après leur achat. Si vous avez besoin d’un
            groupe électrogène d’occasion pour votre chantier ou votre
            entreprise, veuillez contacter Location d'outils industriels Jobsite
            aujourd’hui pour obtenir plus d’information sur notre sélection de
            groupes électrogènes.
          </p>
          <h3>Groupes électrogènes actuellement en vente:</h3>
        </>
      ),
      ExtraText: (
        <>
          <h4>Groupes électrogènes commerciaux et industriels usagés</h4>
          <p>
            Location d'outils industriels Jobsite offre une large sélection de
            groupes électrogènes commerciaux et industriels. Nos groupes
            électrogènes au gaz et au diesel sont conçus pour fournir une
            performance et efficacité supérieures ainsi qu’une plus grande
            économie en carburant. Contactez-nous aujourd’hui pour obtenir plus
            d’information!
          </p>
        </>
      ),
    },
  },
  {
    CatCode: "100a",
    CatCodeSlug: "100a",
    EN: {
      Name: "Systems / Components",
      Meta: "Jobsite Industrial Rental Services carries a wide selection of used commercial and industrial generators. Our used gas and diesel generators are built to world-class standards, for high efficiency and low fuel consumption. Contact us today for more information!",
      Desc: (
        <>
          <p>
            Generators provide power for countless agricultural, construction
            and mining projects across Canada. They are also often required to
            power complex business data centers and hospitals. Jobsite
            Industrial Rental Services carries a large selection of used
            generators for commercial and industrial applications that range in
            power from 40 kW to 2,000 kW. We guarantee that our Caterpillar used
            generators are load tested and regularly serviced to ensure that you
            can use your generator immediately after purchase. Whether you need
            a used generator for your job site or business, contact Jobsite
            Industrial Rental Services today for more information about our used
            generator range.
          </p>
          <h3>Used Generators Currently for Sale: </h3>
        </>
      ),
      ExtraText: (
        <>
          <h4>Used Commercial and Industrial Generators</h4>
          <p>
            Jobsite Industrial Rental Services carries a wide selection of used
            commercial and industrial generators. Our used gas and diesel
            generators are built to world-class standards, for high efficiency
            and low fuel consumption. Contact us today for more information!
          </p>
        </>
      ),
    },
    FR: {
      Name: "COMPOSANTS DE SYSTÈMES",
      Meta: "Location d'outils industriels Jobsite offre une large sélection de groupes électrogènes commerciaux et industriels. Nos groupes électrogènes au gaz et au diesel sont conçus pour fournir une performance et efficacité supérieures ainsi qu’une plus grande économie en carburant. Contactez-nous aujourd’hui pour obtenir plus d’information!",
      Desc: (
        <>
          <p>
            Les groupes électrogènes alimentent une multitude de projets
            miniers, d’agriculture et de construction à travers le Canada. Ils
            sont aussi souvent utilisés pour alimenter des centres hospitaliers
            et des centres complexes de données d’entreprise. Location d'outils
            industriels Jobsite offre une large sélection de groupes
            électrogènes usagés, qui livrent une puissance de 40 kW à 2 000 kW,
            pour des applications commerciales et industrielles. Nous
            garantissons que les groupes électrogènes usagés Caterpillar sont
            testés en charge et régulièrement entretenus afin d’être pouvoir
            utilisé immédiatement après leur achat. Si vous avez besoin d’un
            groupe électrogène d’occasion pour votre chantier ou votre
            entreprise, veuillez contacter Location d'outils industriels Jobsite
            aujourd’hui pour obtenir plus d’information sur notre sélection de
            groupes électrogènes.
          </p>
          <h3>Groupes électrogènes actuellement en vente:</h3>
        </>
      ),
      ExtraText: (
        <>
          <h4>Groupes électrogènes commerciaux et industriels usagés</h4>
          <p>
            Location d'outils industriels Jobsite offre une large sélection de
            groupes électrogènes commerciaux et industriels. Nos groupes
            électrogènes au gaz et au diesel sont conçus pour fournir une
            performance et efficacité supérieures ainsi qu’une plus grande
            économie en carburant. Contactez-nous aujourd’hui pour obtenir plus
            d’information!
          </p>
        </>
      ),
    },
  },
];
export default SEO_BFE;
