import React from "react";
import { isEnvironmentJOBSITE } from "../../common/Constants";
import { outputEnFr, getPhoneNumber } from "../../common/HelperFunctions";

type Props_CtaSectionHeader = {
  lang: string;
  currentCatClass: string;
};

const CtaSectionHeader = (props: Props_CtaSectionHeader) => {
  return (
    <div className="ctabuttons">
      <a
        href={"tel:" + getPhoneNumber(props.currentCatClass)}
        className="top-phone phonenumber-main"
      >
        {getPhoneNumber(props.currentCatClass)}{" "}
      </a>
      <a href="#form" className="btn">
        <span className="data-fr" data-fr="Demande d'information">
          {outputEnFr("Contact Us", "Demande d'information", props.lang)}
        </span>
      </a>
    </div>
  );
};
CtaSectionHeader.whyDidYouRender = true;
export default CtaSectionHeader;
