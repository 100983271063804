import React from "react";
import { outputEnFr } from "../HelperFunctions";
import { isEnvironmentBFERENTAL, PHONENUMBERS } from "../Constants";
import ScrollTopButton from "./ScrollTopButton";

type Props_footer = {
  lang: string;
};

const Footer_Battlefield = ({ lang }: Props_footer) => {
  const curYear = new Date().getFullYear();

  return (
    <footer>
      <a id="to-top" href="#header-top" aria-label="back to top">
        <i className="fa fa-chevron-up" aria-hidden="true"></i>
      </a>
      <div className="container text-center">
        <div className="row">
          <div className="col-lg-12">
            <h2>
              {outputEnFr("Have questions?", "Vous avez des questions?", lang)}
            </h2>
            <span
              dangerouslySetInnerHTML={{
                __html: outputEnFr(
                  "<h3>Call our <span>used equipment specialists</span> today!</h3>",
                  "<h3>Appelez nos <span>spécialistes de l'équipement d'occasion</span> dès aujourd'hui !</h3>",
                  lang
                ),
              }}
            />
            <a className="phone" href="tel:1-866-542-1989">
              1-866-542-1989
            </a>
            <br />
            <p className="hours">
              {outputEnFr(
                "Operating Hours: Monday to Friday 8:00am – 5:00pm",
                "Heures d’ouverture : du lundi au vendredi de 8h00 à 17h00",
                lang
              )}
              &nbsp; | &nbsp;
              {outputEnFr(
                "\u00a9 " +
                  curYear +
                  " Battlefield Equipment Rentals. All Rights Reserved.",
                "\u00a9 " +
                  curYear +
                  " Battlefield Equipment Rentals - Tous droits réservés.",
                lang
              )}
            </p>
            <p>
              <a
                href="//www.toromont.com/copyright.asp"
                target="_blank"
                rel="noopener noreferrer"
                className="policy-links"
              >
                {outputEnFr(
                  "Copyright Information & Legal Notice",
                  "Information de droits d'auteur et mentions légales",
                  lang
                )}
                &nbsp; | &nbsp;
              </a>{" "}
              <a
                href="//www.toromont.com/policy.asp"
                target="_blank"
                rel="noopener noreferrer"
                className="data-fr policy-links"
                data-fr="Politique de confidentialité"
              >
                {outputEnFr(
                  "Website Privacy Statement",
                  "Politique de confidentialité",
                  lang
                )}
                &nbsp; | &nbsp;
              </a>
              <a
                href={outputEnFr(
                  "https://www.toromont.com/accessibility/",
                  "https://www.toromont.com/accessibility/",
                  lang
                )}
                target="_blank"
                rel="noopener noreferrer"
                className="policy-links"
              >
                {outputEnFr("Accessibility", "Accessibilité", lang)}
              </a>
            </p>
          </div>
        </div>
      </div>

      <ScrollTopButton />
    </footer>
  );
};

export default Footer_Battlefield;
