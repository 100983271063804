import React from "react";
import {
  isHeavyEquipmentCategory,
  outputEnFr,
} from "../../common/HelperFunctions";
import { getListingPageUrl } from "../../common/SEO/SeoFunctions";
import { Link } from "react-router-dom";
import * as Constants from "../../common/Constants";
import _ from "lodash";

// BFE category images
import attachmentImage from "../../../images/categoryimagesbfe/home-attachments.jpg";
import compactorImage from "../../../images/categoryimagesbfe/home-compactors.jpg";
import telehandlerImage from "../../../images/categoryimagesbfe/home-telehandlers.jpg";
import backhoeLoaderImage from "../../../images/categoryimagesbfe/home-backhoe-loaders.jpg";
import excavatorImage from "../../../images/categoryimagesbfe/home-excavators.jpg";
import wheelLoaderImage from "../../../images/categoryimagesbfe/home-wheel-loaders.jpg";
import compactTrackLoaderImage from "../../../images/categoryimagesbfe/home-compact-track-loader.jpg";
import skidSteerLoaderImage from "../../../images/categoryimagesbfe/home-skid-steer-loaders.jpg";
import vtrImage from "../../../images/categoryimagesbfe/home-vtr.jpg";
import ILImage from "../../../images/categoryimagesbfe/home-industrial-loaders.jpg";

// BFERental category images
import aircompressorsImage from "../../../images/categoryimagesbferental/air-compressors.jpg";
import backhoeloadersImage from "../../../images/categoryimagesbferental/backhoe-loaders.jpg";
import compactionequipmentImage from "../../../images/categoryimagesbferental/compaction-equipment.jpg";
import compacttrackloadersImage from "../../../images/categoryimagesbferental/compact-track-loaders.jpg";
import concBuggyImage from "../../../images/categoryimagesbferental/concrete-buggy.jpg";
import excavatorsImage from "../../../images/categoryimagesbferental/excavators.jpg";
import generatorsImage from "../../../images/categoryimagesbferental/generators.jpg";
import inoperableImage from "../../../images/categoryimagesbferental/inoperable.jpg";
import inoperableImageFR from "../../../images/categoryimagesbferental/inoperable-fr.jpg";
import lawngardenequipmentImage from "../../../images/categoryimagesbferental/lawn-garden-equipment.jpg";
import lighttowersImage from "../../../images/categoryimagesbferental/light-towers.jpg";
import materialhandlingImage from "../../../images/categoryimagesbferental/material-handling.jpg";
import messagearrowboardsImage from "../../../images/categoryimagesbferental/message-arrow-boards.jpg";
import miscImage from "../../../images/categoryimagesbferental/misc.jpg";
import miscImageFR from "../../../images/categoryimagesbferental/misc-fr.jpg";
import pumpsImage from "../../../images/categoryimagesbferental/pumps.jpg";
import scissorliftsboomsImage from "../../../images/categoryimagesbferental/scissor-lifts-booms.jpg";
import skidsteerloadersImage from "../../../images/categoryimagesbferental/skid-steer-loaders.jpg";
import trenchshoringImage from "../../../images/categoryimagesbferental/trench-shoring.jpg";
import weldersImage from "../../../images/categoryimagesbferental/welders.jpg";

// Jobsite category images
import hoistingImage from "../../../images/categoryimagesjobsite/hoisting-rigging.jpg";
import materialImage from "../../../images/categoryimagesjobsite/material-handling-machinery-moving.jpg";
import jacksImage from "../../../images/categoryimagesjobsite/jacks.jpg";
import pipeBendingImage from "../../../images/categoryimagesjobsite/pipe-bending.jpg";
import pipeFabImage from "../../../images/categoryimagesjobsite/pipe-fabrication.jpg";
import wireImage from "../../../images/categoryimagesjobsite/wire-cable-handling.jpg";
import electricToolsImage from "../../../images/categoryimagesjobsite/electric-tools.jpg";
import airToolsImage from "../../../images/categoryimagesjobsite/air-tools.jpg";
import confinedImage from "../../../images/categoryimagesjobsite/confined-space.jpg";
import storageImage from "../../../images/categoryimagesjobsite/storage-boxes.jpg";
import torqueImage from "../../../images/categoryimagesjobsite/torqueing-bolting.jpg";
import weldingImage from "../../../images/categoryimagesjobsite/welding-power-distribution.jpg";
import miscJobsiteImage from "../../../images/categoryimagesjobsite/misc.jpg";

type Props_CategorySection = {
  lang: string;
  jsonDataGroups: any;
};

const fadeInClass = navigator.userAgent === "ReactSnap" ? "fade-in" : "";

const CategorySection: React.FC<Props_CategorySection> = (props) => {
  console.log("Rendering CategorySection", props);

  const getLink = (
    groupCode: string,
    groupDisplayName: string,
    groupCount?: number,
    groupImage?: string
  ) => {
    const href = getListingPageUrl(
      props.lang,
      groupCode,
      false,
      Constants.searchFiltersEmptyDefault
    );
    const linkClasses = groupCount ? "homeequiplink" : "homeequiplink default";
    return (
      <div className="card" key={groupCode}>
        <Link to={href}>
          <img src={groupImage} alt={groupDisplayName} />
        </Link>
        <Link className={linkClasses} to={href}>
          {props.lang === "en" && groupDisplayName === "Vtrd"
            ? "Vibratory Tandem Rollers"
            : props.lang === "fr" && groupDisplayName === "Vtrd"
            ? "Rouleaux tandems vibrants"
            : groupDisplayName === "Vtrd"
            ? "Vibratory Tandem Rollers"
            : props.lang === "en" && groupDisplayName === "Il"
            ? "Industrial Loaders"
            : props.lang === "fr" && groupDisplayName === "Il"
            ? "Chargeurs industriels"
            : groupDisplayName === "Il"
            ? "Industrial Loaders"
            : groupDisplayName}
          &nbsp;
          {groupCount && (
            <strong className="badge badge-primary">{groupCount}</strong>
          )}
        </Link>
      </div>
    );
  };

  const numberOfColumns = 4;
  const columnClass = "col-md-3"; // Always use 4 columns

  // Grouped category images object (BFE)
  const categoryImagesBFE: { [key: string]: string } = {
    "Work Tools - Outils De Travail": attachmentImage,
    "Compactors - Compacteurs": compactorImage,
    "Telehandlers - Chargeurs À Bras Télescopique": telehandlerImage,
    "Backhoe Loaders - Chargeuses-pelleteuses": backhoeLoaderImage,
    "Excavators - Pelles Hydrauliques": excavatorImage,
    "Wheel Loaders / Integrated Toolcarriers - Chargeurs Sur Pneus / Chargeurs Industriels":
      wheelLoaderImage,
    "COMPACT TRACK LOADER - COMPACT TRACK LOADER": compactTrackLoaderImage,
    "Skid Steer Loaders / ASV - Chargeurs Compacts rigides":
      skidSteerLoaderImage,
    "VTRD": vtrImage,
    "IL": ILImage,
  };

  // Grouped category images object (BFERENTAL)
  const categoryImagesBFERental: { [key: string]: string } = {
    "BFE001": aircompressorsImage,
    "BFE013": backhoeloadersImage,
    "BFE012": compactionequipmentImage,
    "BFE010": compacttrackloadersImage,
    "BFE007": concBuggyImage,
    "BFE011": excavatorsImage,
    "BFE002": generatorsImage,
    "BFE018": props.lang === "en" ? inoperableImage : inoperableImageFR,
    "BFE008": lawngardenequipmentImage,
    "BFE003": lighttowersImage,
    "BFE014": materialhandlingImage,
    "BFE006": messagearrowboardsImage,
    "BFE015": props.lang === "en" ? miscImage : miscImageFR,
    "BFE004": pumpsImage,
    "BFE005": scissorliftsboomsImage,
    "BFE009": skidsteerloadersImage,
    "BFE017": trenchshoringImage,
    "BFE016": weldersImage,
  };

  // Grouped category images object (Jobsite)
  const categoryImagesJobsite: { [key: string]: string } = {
    "JEIJ01": hoistingImage,
    "JEIJ02": materialImage,
    "JEIJ03": jacksImage,
    "JEIJ04": weldingImage,
    "JEIJ05": pipeBendingImage,
    "JEIJ06": pipeFabImage,
    "JEIJ07": wireImage,
    "JEIJ08": electricToolsImage,
    "JEIJ09": airToolsImage,
    "JEIJ10": confinedImage,
    "JEIJ11": storageImage,
    "JEIJ12": torqueImage,
    "JEIJ13": miscJobsiteImage,
  };

  let categoryLinks: JSX.Element[] = [];

  if (props.jsonDataGroups) {
    const sortedData = _.sortBy(props.jsonDataGroups, (o) =>
      o["group-display-name"] ? _.deburr(o["group-display-name"]) : ""
    );

    categoryLinks = sortedData.reduce((acc: JSX.Element[], group) => {
      if (Constants.isEnvironmentBFE || isHeavyEquipmentCategory(group)) {
        let imageSource: string | undefined;
        if (Constants.isEnvironmentBFE || Constants.isEnvironmentTCAT) {
          imageSource = categoryImagesBFE[group["group-code"]];
        } else if (Constants.isEnvironmentBFERENTAL) {
          imageSource = categoryImagesBFERental[group["group-code"]];
        } else if (Constants.isEnvironmentJOBSITE) {
          imageSource = categoryImagesJobsite[group["group-code"]];
        }

        acc.push(
          getLink(
            group["group-code"],
            group["group-display-name"],
            group.count,
            imageSource
          )
        );
      }
      return acc;
    }, []);
  }

  // Distribute categories evenly across rows and columns
  const numberOfRows = Math.ceil(categoryLinks.length / numberOfColumns);
  const rows = Array.from({ length: numberOfRows }, (_, rowIndex) =>
    categoryLinks.slice(
      rowIndex * numberOfColumns,
      (rowIndex + 1) * numberOfColumns
    )
  );

  return (
    <section id="sectionFamilyList">
      <div className="clearfix"></div>
      <div className="container">
        <div className="text-center">
          <h2>
            <span
              dangerouslySetInnerHTML={{
                __html: outputEnFr(
                  "<h2><span>Equipment</span> Categories</h2>",
                  "<h2>CATÉGORIES <span>D'ÉQUIPEMENTS</span></h2>",
                  props.lang
                ),
              }}
            />
          </h2>
        </div>

        <div id="mainProdFamList" className={fadeInClass}>
          {props.jsonDataGroups ? (
            rows.map((row, rowIndex) => (
              <div key={rowIndex} className="row">
                {row.map((category, colIndex) => (
                  <div key={colIndex} className={columnClass}>
                    {category}
                  </div>
                ))}
              </div>
            ))
          ) : (
            <span className="loading">
              {outputEnFr(
                "Loading products, please wait...",
                "Chargement des produits, veuillez patienter...",
                props.lang
              )}
            </span>
          )}
        </div>
        <div className="clearfix"></div>
      </div>
    </section>
  );
};

export default CategorySection;
